.riepilogo__wrapper {
  &.printed {
    -webkit-print-color-adjust: exact;
  }

  display: flex;
  align-items: flex-start;
  //flex-wrap: wrap;
  height: 75vh;
  min-height: 780px;
  width: 95%;
  justify-content: center;
  margin: $lg-space auto $sm-space auto;

  &.standard-plan-sup {
    .section-planimetry__bottom {
      display: flex;
      flex-direction: column;
    }

    .services {
      height: 100%;

      &:not(.apt-sup) {
        > div {
          border: none;
        }
      }
    }

    .services.apt-sup {
      padding-bottom: 10px;
      padding-top: 10px;
    }

    .services:not(.apt-sup) {
      & > div {
        &:before {
          content: unset;
        }

        & > div {
          width: unset;
        }
      }
    }
  }
}

.riepilogo__wrapper-print {
  display: none;
}

.riepilogo__section {
  flex-basis: calc(33% - 1.5rem);
  height: 100%;
}

.riepilogo__section h6.title {
  font-family: $primary-font;
  color: $text-color;
  text-transform: uppercase;
  font-size: 0.8rem;
  text-align: left;
  font-weight: bold;
  position: absolute;
  left: 2rem;
  top: 1rem;
}

.riepilogo__section h6.title span {
  color: $primary-color;
}

.riepilogo__section h6.title::before {
  content: '';
  background-color: $success-color;
  height: 3px;
  width: 20px;
  display: block;
  position: relative;
  top: 7px;
  left: -32px;
}

// image side
.riepilogo__section.section-image {
  background-color: $white;
  background-size: cover;
  background-position: 70% center;
  min-height: 600px;
  height: 100%;
  border-width: 0.7rem;
  border-color: $success-color;
  border-style: solid;
  box-sizing: border-box;
}

.riepilogo__section.section-image.upselling-button {
  height: 108%;
}

// planimetry side
.riepilogo__section.section-planimetry {
  background-color: $white;
  margin-left: 0.5rem;
}

.riepilogo__section.section-planimetry .plan-image {
  @include icon(90%, no-repeat, center 90%, 100%, 70%);
  background-color: rgba(0, 0, 0, 0.005);
  min-height: 400px;
  position: relative;
  //padding: 5rem 0 0 0;
  box-sizing: border-box;
}

.riepilogo__section.section-planimetry .plan-image:before {
  content: none;
}

.riepilogo__section.section-planimetry .plan-image__button-container {
  bottom: inherit;
  top: -10px;
}

.riepilogo__section.section-planimetry .plan-image__button {
  margin: 0 auto;
  border: 10px solid $background-color;
  box-shadow: 0 0 0 10px #f4edeb;

  &:hover {
    cursor: pointer;
  }
}

.section-planimetry__bottom {
  background-color: $success-color;
  height: 30%;
  padding: 0.7rem;
  box-sizing: border-box;
  position: relative;
}

.section-planimetry__bottom.upselling-button {
  padding: 0.4rem;
  box-sizing: border-box;
  position: relative;
  height: unset;
}

.section-planimetry__bottom h6.title {
  top: 1.5rem;
  left: 2.7rem;
}

.section-planimetry__bottom .services {
  background-color: $white;
  margin: 0;
  padding: 3rem 0 1rem 0;
}

.section-planimetry__bottom .services > div b {
  font-size: 0.9rem;
  height: 35px;
  margin: 0.8rem auto 0.2rem auto;
}

.section-planimetry__bottom .services > div {
  flex: 1 0 25%;
}

.section-planimetry__bottom .services > div {
  font-size: 0.82rem;
}

.services > div b {
  font-size: 0.8rem;
  height: 35px;
  width: 100px;
}

.section-planimetry__bottom .button {
  display: table;
  font-size: 1.1rem;
  margin: 1rem auto 0 auto !important;
  padding: 0rem 1.5rem 0.8rem 1.5rem;
  //background-color: $white;
  font-weight: bold !important;
}

.section-planimetry__bottom p {
  font-family: $secondary-font;
  text-align: center;
  font-size: 0.7rem;
  margin: 0;
}

// planimetry side
.riepilogo__section.section-economics {
  background-color: $success-color;
  flex-basis: 33%;
  display: flex;
  flex-wrap: wrap;
  //margin: 0 0 0 3rem;
  margin: 0 0 0 0.5rem;
  padding: 0.7rem;
  box-sizing: border-box;
}

.riepilogo__section.section-economics.upselling-button {
  height: 108%;
}

.riepilogo__section.section-economics > div {
  flex-basis: 100%;
}

.riepilogo__section.section-economics .section-economics__top {
  background-color: $white;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  box-sizing: border-box;
  height: 20%;
}

.riepilogo__section.section-economics .section-economics__top h6 {
  font-family: $primary-font;
  font-size: 1rem;
  //font-weight: 900;
  margin: 0 0 0rem 0;
}

.riepilogo__section.section-economics .section-economics__top h2 {
  font-family: $secondary-font;
  font-size: 2.7rem;
  font-weight: bold;
  margin: 0 0 0.3rem 0;
}

.riepilogo__section.section-economics .section-economics__top p {
  font-size: 0.7rem;
  margin: 0.2rem 0 0 0;
}

.riepilogo__section.section-economics .section-economics__top .price {
  font-family: $secondary-font;
  font-weight: bold;
  font-size: 1.5rem;
}

.riepilogo__section.section-economics .section-economics__middle {
  background-color: $background-color;
  text-align: center;
  margin: 0 0 0.7rem 0;
  padding: 3rem 1rem 1rem 1rem;
  box-sizing: border-box;
  position: relative;
  min-height: calc(50% - 0.7rem);
}

.riepilogo__section.section-economics .section-economics__middle p {
  font-size: 0.7rem;
  margin: 0.2rem 0 0 0;
}

.riepilogo__section.section-economics .section-economics__middle .promo {
  display: flex;
  justify-content: space-around;
  margin: 1.5rem 0;
  position: relative;
  color: $success-color;
}

.riepilogo__section.section-economics .section-economics__middle .promo > div {
  flex: 0 0 44%;
  margin: 0 2%;
  padding: 0.5rem 0 1.5rem 0;
  border-bottom: 3px solid $secondary-color;
  position: relative;
}

.riepilogo__section.section-economics .section-economics__middle .promo > div:first-child:after {
  content: '';
  background-color: $secondary-color;
  width: 3px;
  height: 100%;
  display: inline-block;
  position: absolute;
  right: -7%;
  top: 0;
}

.riepilogo__section.section-economics .section-economics__middle .promo h6 {
  color: $success-color;
  font-size: 0.7rem;
  text-transform: uppercase;
  margin: 0 0 0.2rem 0;
}

.riepilogo__section.section-economics .section-economics__middle .promo .price {
  font-family: $secondary-font;
  font-weight: 900;
  font-size: 2rem;
}

.riepilogo__section.section-economics .section-economics__middle .promo .price.gift::before {
  content: '';
  @include icon(contain, no-repeat, center, 16px, 16px);
  display: inline-block;
  position: relative;
  top: -2px;
  left: -5px;
}

// services
.services {
  display: flex;
  text-align: center;
  justify-content: space-between;
}

.services > div:before {
  content: '';
  @include icon(70%, no-repeat, center, 45px, 45px);
  border: 1px solid $primary-color;
  border-radius: 50%;
  display: block;
  margin: 0.5rem auto;
}

.services > div {
  flex: 1 0 auto;
  min-width: 20%;
  padding: 0.5rem;
  box-sizing: border-box;
  border-right: 1px solid #c7c7c7;
}

.services > div:last-child {
  border-right: none;
}

.services > div > * {
  width: 100%;
  display: block;
}

.services > div b {
  font-family: $primary-font;
  font-size: 0.95rem;
  height: 40px;
  margin: 1.2rem auto 0.2rem auto;
}

.riepilogo__section.section-economics .section-economics__middle .promo p {
  color: $success-color;
  font-family: $secondary-font;
  font-size: 0.8rem;
}

.riepilogo__section.section-economics .section-economics__middle .buono {
  position: absolute !important;
  right: 0;
  top: -1px;
}

.riepilogo__section.section-economics .section-economics__middle .buono .button {
  background-color: $primary-color;
  font-size: 0.7rem;
  line-height: 1;
  padding: 0.4rem 2rem 0.4rem 1rem;
  width: 120px;
  position: absolute;
  right: -0.5rem;
  bottom: -5.7rem;
}

.riepilogo__section.section-economics .section-economics__middle .buono .button:after {
  content: '';
  @include icon(contain, no-repeat, center, 36px, 36px);
  display: block;
  position: absolute;
  right: 0;
  top: -5px;
}

.riepilogo__section.section-economics .section-economics__middle .final-cost h5 {
  font-family: $primary-font;
  //font-weight: normal;
  letter-spacing: 0.05em;
  font-size: 1rem;
  margin: 1.5rem 0 0 0 !important;
}

.riepilogo__section.section-economics .section-economics__middle .final-cost .price {
  font-family: $secondary-font;
  margin: 0.5rem 0 0 0;
  font-weight: 900;
  font-size: 2.7rem;
}

.riepilogo__section.section-economics .section-economics__middle .button {
  background-color: $primary-color;
  color: $white;
  font-size: 1rem;
  line-height: 1.5;
  text-transform: uppercase;
  margin: 2rem 0 0 0;
  padding: 0.5rem 2.5rem;
  display: inline-block;
  position: relative;

  &:hover {
    background-color: $tertiary-color;
    opacity: 0.9;
  }
}

.riepilogo__section.section-economics .section-economics__middle .final-cost p {
  font-size: 0.7rem;
}

.riepilogo__section.section-economics .section-economics__middle .rata {
  width: 100%;
  float: right;
  margin: 0 auto !important;
}

.riepilogo__section.section-economics .section-economics__middle .rata h6 {
  font-size: 0.7rem;
  font-weight: normal;
  text-transform: uppercase;
  margin: 0 0 0 0;
}

.riepilogo__section.section-economics .section-economics__middle .rata .price {
  font-family: $secondary-font;
  font-weight: 900;
  font-size: 1.4em;
  margin: 0.5rem 0 0 0;
}

.riepilogo__section.section-economics .section-economics__middle .buono .button:hover {
  opacity: 0.9;
}

.riepilogo__section.section-economics .section-economics__bottom {
  background-color: $white;
  //padding: 0.5rem;
  box-sizing: border-box;
  position: relative;
  /*display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 5px; */
  display: flex;
  min-height: calc(30% - 1.4rem);
}

.riepilogo__section.section-economics .section-economics__bottom > div {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.riepilogo__section.section-economics .section-economics__bottom .box-4 {
  grid-area: 2 / 2 / 3 / 4;
}

.riepilogo__section.section-economics .section-costs__field-container {
  display: flex;
  flex-direction: column;
  padding: 0 3rem;
  gap: 1rem;
}

// CONTACTS

div#contact {
  background: $tertiary-color;
  color: $white;
  margin: $lg-space 0 1rem 0;
  padding: $lg-space;
  display: flex;
  justify-content: center;

  h3 {
    color: $primary-color;
    font-size: 1.5rem;
    line-height: 1.2;
    text-align: center;

    span {
      font-family: $secondary-font;
      font-style: italic;
      font-size: 1.8rem;
      font-weight: 400;
      //letter-spacing: 0.02em;
    }

    .subtitle {
      font-weight: 400;
      padding: 1rem 0;
      margin: 0 0 1rem 0;
      border-bottom: 1px solid $primary-color;
      color: $primary-color;
      font-size: 0.9rem;
      line-height: 1.3;
      text-align: center;
      letter-spacing: 3px;
      display: inline-block;
      width: 90%;
    }
  }

  > div {
    flex: 0 0 50%;
    max-width: 500px;
    margin: 0 1rem;
  }

  .showroom {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 5px;
    grid-row-gap: 5px;

    .box {
      @extend %background-image;
      background-size: cover;
      min-height: 150px;
    }

    .box:nth-child(1) {
      grid-area: 1 / 1 / 3 / 3;
    }

    .box:nth-child(2) {
      grid-area: 3 / 1 / 6 / 2;
    }

    .box:nth-child(3) {
      grid-area: 3 / 2 / 5 / 3;
    }
  }

  .contact-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 95%;
    max-width: 960px;
    margin: 0 auto;

    > * {
      flex: 0 0 100%;
      margin: 0.5rem 0;
    }

    input:not(.submit-button),
    textarea {
      color: $primary-color;
      height: 38px;
      border: none;
      border-bottom: 1px solid $primary-color;
      //font-family: $secondary-font;
      font-size: 0.8rem;
      padding: 0.2rem 0.5rem;
      box-sizing: border-box;
      box-shadow: none;
    }

    textarea {
      height: 100px;
      padding: 0.5rem;
    }

    label span {
      color: $primary-color;
    }

    .submit-button {
      background-color: $primary-color;
      color: $white;
      text-transform: uppercase;
      font-weight: bold;
      flex: 0 0 185px;
      margin: 1rem 0 2rem 0;
      padding: 0.7rem 1.5rem;
      font-size: 0.9rem;
      position: relative;
      border: 0;
      //border-radius: 40px;
      margin-left: auto;
      cursor: pointer;
      transition: color 0.3s, background-color 0.3s, box-shadow 0.3s;

      &:hover {
        background-color: #fff;
        color: $primary-color;
        box-shadow: 0 0 0 2px $primary-color;
      }
    }

    .w-checkbox {
      font-size: 0.75rem;
      line-height: 1.3;
      letter-spacing: 0.03em;
      margin: 0.2rem 0 1rem 2.5rem;
      position: relative;

      a {
        color: $white;
        text-decoration: underline;
      }

      input {
        position: absolute;
        left: -20px;
        top: -10px;
      }
    }
  }

  .field-label,
  .w-form-done,
  .w-form-fail {
    display: none;
  }
}

// Responsive

@media screen and (max-width: 1600px) {
  .riepilogo__section.section-image {
    display: none;
  }

  .riepilogo__section {
    flex-basis: calc(50% - 1.5rem);
  }

  .riepilogo__section.section-planimetry .plan-image {
    background-size: 65%;
  }

  .riepilogo__section.section-economics {
    flex-basis: calc(50% - 2.5rem);
  }
}

@media screen and (max-width: 1400px) {
  .riepilogo__section.section-planimetry .plan-image {
    background-size: 75%;
  }
}

@media screen and (max-width: 1300px) {
  .riepilogo__section.section-planimetry .plan-image {
    background-size: 100%;
    background-position: center;
  }
}

@media screen and (max-width: $small-desktop) {
  .riepilogo__wrapper {
    flex-wrap: wrap;
    height: inherit;
  }

  .riepilogo__section {
    flex-basis: 100%;
    margin: 0 !important;
  }

  .riepilogo__section.section-planimetry .plan-image {
    background-size: 35%;
  }

  .riepilogo__section.section-economics {
    flex-basis: 100%;
    order: 1;
  }

  .riepilogo__section.section-planimetry {
    order: 2;
    margin: 2rem 0 0 0 !important;
  }

  .riepilogo__section.section-economics .section-economics__bottom {
    display: none;
  }
}

@media screen and (max-width: $tablet) {
  .riepilogo__section.section-planimetry .plan-image {
    background-size: 45%;
  }

  div#contact {
    flex-wrap: wrap;

    > div {
      flex: 0 0 100%;

      &:nth-child(2) {
        margin-top: 3rem;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .riepilogo__wrapper {
    margin: 1rem auto 0rem auto;
  }

  .riepilogo__section h6.title {
    font-size: 0.9rem;
    text-align: center;
    //margin: 2rem auto 1rem auto;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
  }

  .riepilogo__section h6.title:before {
    content: none;
  }

  .section-planimetry__bottom .services {
    flex-wrap: wrap;
  }

  .section-planimetry__bottom .services > div {
    flex: 0 0 50%;
  }

  .riepilogo__section.section-planimetry .plan-image {
    min-height: 350px;
    background-size: 65%;
    height: 100%;
    padding: 0;
  }

  .riepilogo__section.section-economics .round-label {
    font-size: 2rem;
  }

  .riepilogo__section.section-economics {
    padding: 0.5rem;
  }

  .riepilogo__section.section-economics .section-economics__middle {
    margin-bottom: 0;
  }

  .riepilogo__section.section-economics .section-economics__middle .rata .price {
    padding: 0.2rem 1.8rem;
  }

  .riepilogo__section.section-economics .section-economics__middle .buono p {
    margin-top: 0.6rem;
    line-height: 1;
  }

  .riepilogo__section.section-economics .section-economics__middle .buono p span {
    display: block;
  }

  .riepilogo__section.section-economics .section-economics__bottom {
    padding: 1rem;
  }

  .riepilogo__section.section-economics .section-economics__bottom h3 {
    font-size: 2.5rem;
  }

  .riepilogo__section.section-economics .section-economics__bottom .buttons {
    flex-wrap: wrap;
  }

  .riepilogo__section.section-economics .section-economics__bottom p {
    font-size: 0.85rem;
    margin: -0.7rem 0 0 0;
  }

  .riepilogo__section.section-economics .section-economics__bottom .button {
    flex: 0 0 60%;
    margin: 1rem 0 0.5rem 0;
  }

  .section-economics__bottom h6.title {
    line-height: 0.9;
    margin: 2rem auto 1rem auto;
    font-weight: normal;
    position: static;
    transform: none;
    letter-spacing: 0.05em;
  }

  .section-economics__bottom h6.title span {
    font-weight: 900;
    display: block;
    line-height: 1.5;
  }

  .riepilogo__section.section-economics .section-economics__middle .rata {
    width: 100%;
    margin: 2rem 0 0rem 0;
  }

  .section-planimetry__bottom .services > div b {
    height: 20px;
  }

  // section go to desktop

  .section__gotodesktop {
    background-color: $white;
    border-radius: 20px 20px 0 0;
    padding: 1rem 0;
    margin: 3rem 0 0 0;
    box-shadow: 1px -3px 6px -4px rgba($black, 0.7);
  }

  .section__gotodesktop h6 {
    font-size: 0.9rem;
    text-align: center;
    margin-top: 1.5rem;
  }

  .separator {
    background-color: rgba($black, 0.2);
    height: 10px;
    width: 40%;
    margin: 0 auto 1rem auto;
    display: block;
    border-radius: 40px;
  }

  .section__gotodesktop h2 {
    font-family: $secondary-font;
    color: rgba($text-color, 0.7);
    font-size: 1.9rem;
    line-height: 1.6;
    font-weight: normal;
    font-style: italic;
    text-align: center;
    text-transform: none;
    margin: 0 0 1rem 0;
  }

  .section__gotodesktop hr {
    width: 90%;
    margin: 0 auto;
    border: 1px solid $success-color;
  }

  .section__gotodesktop .image-grid {
    display: grid;
    max-width: 90%;
    margin: 0 auto;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .section__gotodesktop .image-grid .box {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 150px;
    margin: 2px;
  }

  .section__gotodesktop .image-grid .box-1 {
    grid-area: 1 / 1 / 2 / 2;
  }

  .section__gotodesktop .image-grid .box-2 {
    grid-area: 1 / 2 / 2 / 3;
  }

  .section__gotodesktop .image-grid .box-3 {
    grid-area: 1 / 3 / 3 / 4;
  }

  .section__gotodesktop .image-grid .box-4 {
    grid-area: 2 / 1 / 3 / 3;
  }

  .section__gotodesktop .section-bottom {
    background-color: rgba($black, 0.03);
    margin: 2rem 0 0 0;
    padding: 1rem 0 1rem 0;
    box-shadow: 1px -3px 6px -4px rgba($black, 0.7);
  }

  .section__gotodesktop .section-bottom h2 {
    text-align: left;
    font-size: 1.4rem;
    line-height: 1;
    font-style: italic;
    display: table;
    margin: 1rem auto;
    position: relative;
    right: -50px;
  }

  .section__gotodesktop .section-bottom h2:before {
    content: '';
    @include icon(contain, no-repeat, center, 64px, 64px);
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -75px;
  }

  .section__gotodesktop .section-bottom h2 span {
    color: $success-color;
    font-weight: bold;
  }

  .section__gotodesktop .section-bottom h6 span {
    color: $success-color;
  }
}

.button-upselling-wrapper {
  margin: auto;
}

.section-planimetry__bottom .servicesButton {
  background-color: $white;
  margin-top: -18px;
  height: 85px !important;
}

.parNote {
  margin-right: 0px;
  margin-left: 0px;
  margin-top: 11px;
  text-align: left;
  letter-spacing: 1px;
  padding: 0 15px 0 0;

  ul {
    padding-left: 0px !important;
  }
}

.parNote.site {
  margin-top: unset;
}

@media screen and (max-width: 1600px) {
  .parNote {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.refNote {
  font-size: 10px;
  vertical-align: super;
}

.subPar {
  color: #000;
}

.footer-notes {
  width: 85%;
  margin: 1.5rem auto 1.5rem auto;
  opacity: 0.5;

  p {
    font-family: $primary-font;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  li,
  .parNote {
    font-family: $primary-font;
    font-size: 12px;
    font-weight: 400;
  }

  .notes-content {
    height: 3rem;
    overflow: hidden;

    &.open {
      height: auto;
    }

    .disclaimer {
      margin-bottom: 0;
    }
  }

  .footer-btn {
    font-family: $primary-font;
    font-size: 14px;
    padding: 1rem 0 0 0;
    border: 0;
    background-color: transparent;
  }

  @media screen and (max-width: 1600px) {
    width: 90%;
    margin-left: 3% !important;
  }

  @media screen and (max-width: 1400px) {
    width: 85%;
    margin-left: auto !important;
  }

  @media screen and (max-width: 991px) {
    width: 95%;
    margin-left: auto !important;
  }
}

.priceFormatCapSma {
  font-size: 24px;
  margin-left: 260px;
  text-transform: lowercase;
  font-family: europa, sans-serif;
  font-style: normal;
  font-weight: bold;
}

.capitolato-price {
  font-size: 24px;
  margin-left: 225px;
  text-transform: lowercase;
  font-family: europa, sans-serif;
  font-style: normal;
  font-weight: bold;
}

.capitolato-label {
  text-align: left;
  text-transform: uppercase;
  font-family: europa, sans-serif;
  font-style: normal;
  font-weight: 300;
  justify-content: space-between;
  display: flex;
}

.capitolato-name {
  text-decoration: underline;
  font-weight: bold;
  margin-left: 3px;
}

.margin-reducer {
  margin-top: 10px;
  margin-bottom: 0%;
}

.separator-info-appartment {
  margin-top: 20px;
  height: 1px;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: black;
  align-content: center;
}

.label-total-appartment {
  margin-top: 50px;
  text-align: left;
  text-transform: uppercase;
  font-family: europa, sans-serif;
  font-style: normal;
  font-weight: 300;
}

.price-total-appartment {
  font-size: 28px;
  text-transform: lowercase;
  margin-left: 285px;
  font-family: europa, sans-serif;
  font-style: normal;
  font-weight: bold;
  color: #000;
}

.appartment-price-wrapper {
  font-family: europa, sans-serif;
  font-style: normal;
  font-weight: 300;
  text-align: left;
  justify-content: space-between;
  display: flex;
}

.price-format {
  font-size: 24px;
  color: #000;
  font-family: europa, sans-serif;
  font-style: normal;
  font-weight: bold;
}

.section-planimetry__bottom {
  .services {
    display: flex !important;

    & > div {
      flex: 1 0 auto !important;
      flex-grow: 1 !important;
    }
  }
}

.notaPreventivo {
  max-width: 60%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 20px;
  font-size: 25px;
}

.plan-image-top {
  display: flex;
  justify-content: center;

  .plan-image__button {
    display: inline-flex;
    margin: 0 !important;
  }

  .plan-image__button--technical-plan {
    margin-left: 40px !important;
    box-shadow: 3px 4px 3px 0 rgba(0, 0, 0, 0.2) !important;
  }

  .button-link-print {
    margin-left: 30px !important;
  }
}

.modal__content {
  .button-link-print-pdf {
    width: 70px !important;
    height: 70px !important;
    border: unset !important;
    z-index: 1 !important;
    bottom: 30% !important;
    right: 5% !important;
    position: absolute !important;
    cursor: pointer !important;
  }

  .button-link-print-png {
    width: 70px !important;
    height: 70px !important;
    border: unset !important;
    z-index: 1 !important;
    bottom: 20% !important;
    right: 5% !important;
    position: absolute !important;
    cursor: pointer !important;
  }

  .button-link-plansend {
    width: 70px !important;
    height: 70px !important;
    border: unset !important;
    z-index: 1 !important;
    bottom: 10% !important;
    right: 5% !important;
    position: absolute !important;
    cursor: pointer !important;
  }

  .send-plan-disabled {
    opacity: 0.5 !important;
    cursor: auto !important;
  }
}

#ico-piano {
  .summary-piano-label {
    height: 30px !important;
    width: 150px !important;
    text-align: center;
    font-size: 0.9rem;
    margin: 0.8rem auto 0.2rem auto;
    font-family: p22-underground, sans-serif;
    display: block;
    font-weight: 700;
  }
}

.apt-unavailable-text {
  margin-top: 20%;
  font-size: 20px;
}

@media print {
  .riepilogo__wrapper-print {
    display: block !important;
    margin: 20px auto 20px auto;

    .main-logo {
      width: 250px;
      height: 90px;
      display: block;
      margin: auto;
    }

    .fields-wrapper {
      margin: auto;
      width: 550px;
    }

    .print-plan-image {
      height: 400px;
    }

    .print-plan-wrapper {
      border: #8aa291 1px solid;
      padding: 10px;
      margin: 0px;
      width: 580px;
      margin-left: auto;
      margin-right: auto;
      height: 400px;
    }

    .summary-subtitle {
      font-size: 14px;
      font-family: Arial, sans-serif;
      color: #575756;
      line-height: 14px;
      margin-bottom: 10px;
    }

    .apt-main-wrapper {
      font-size: 19px;
      font-family: Arial, sans-serif;
      color: #8aa291 !important;
      border: #8aa291 3px solid;
      display: inline-block;

      .apt-main {
        color: #8aa291 !important;
        display: inline-block;
        margin: 10px;
      }
    }

    .apt-info-table {
      width: 556px;
      font-size: 13px;
      font-family: Arial, sans-serif;
      color: #575756;

      .apt-info-title-wrapper {
        width: 332px;
        vertical-align: bottom;

        .apt-info-title {
          text-align: left;
          margin-top: 0px;
          font-size: 14px;
          font-family: Arial, sans-serif;
          color: #575756;
          line-height: 16px;
        }
      }

      .apt-info-value-wrapper {
        .apt-info-value {
          text-align: right;
          font-size: 14px;
          font-family: Arial, sans-serif;
          font-weight: bold;
          line-height: 16px;
        }
      }

      .table-underline-wrapper {
        border-bottom: #9d9d9c 1px solid;

        .table-underline {
          line-height: 12px;
          margin-top: 0px;
        }
      }

      .title-wrapper {
        height: 48px;
        vertical-align: bottom;
        width: 556px;
        font-size: 8px;
        font-family: Arial, sans-serif;
        font-weight: bold;

        .title {
          line-height: 18px;
          font-size: 16px;
        }
      }

      .value-wrapper {
        vertical-align: bottom;
        width: 556px;
        font-size: 8px;
        font-family: Arial, sans-serif;
        font-weight: bold;

        .value {
          text-align: right;
          line-height: 16px;
          font-size: 16px;
        }
      }
    }

    .policies-wrapper {
      margin-top: 100px;

      .policy-wrapper {
        text-align: left;
        margin-top: 0px;
        margin-bottom: 5px;
        font-size: 8px;
        font-family: Arial, sans-serif;
        font-weight: bold;

        .policy {
          color: #575756;
          line-height: 10px;

          .policy-text {
            font-size: 11px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 390px) {
  #floorplanning-app .services > div b {
    width: unset !important;
  }
}
