.fullscreen-toggler {
  border-radius: 50px;
  border: 2.5px solid #fff;
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;
}
