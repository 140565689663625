.configurator-boxes {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  @extend %content-layout;
  border: 1px solid $primary-color;
  &:after {
    content: '';
    background-size: cover;
    background-repeat: no-repeat;
    width: calc(50vw - 13px);
    height: 450px;
    position: absolute;
    z-index: -1;
    left: 0px;
    bottom: 0;
  }

  .box {
    @extend %background-image;
    @extend %background-image--overlay;
    //background-color: $primary-color;
    background-size: cover;
    background-position: 30% center;
    color: $white;
    min-height: 270px;
    height: 28vh;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    position: relative;
    width: 100%;
    .box__description {
      .box__description--top {
        font-size: 0.8rem;
        margin-bottom: $md-space;
      }
      .box__description--main {
        font-size: 1.6rem;
        font-weight: 700;
        margin-bottom: 0.4rem;
        padding: 0 1rem;
        text-overflow: ellipsis;
        overflow: hidden;
        @media screen and (max-width: $mobile) {
          font-size: 1.5rem;
        }
      }
      .box__description--subtitle {
        font-size: 1.1rem;
        margin: 0.4rem 0 1rem 0;
      }
      .box__description--go-icon {
        @include icon(cover, no-repeat, center, 50px, 50px);
        display: inline-block;
      }
      .box__description--bottom {
        font-size: 0.8rem;
        position: absolute;
        bottom: $md-space;
        @extend %center-absolute-X;
        z-index: 2;
      }
    }
    &.not-available {
      filter: grayscale(100%);
      cursor: not-allowed;
    }
  }

  // 6 elements
  &.grid-6 {
    .box:nth-child(1) {
      grid-area: 1 / 1 / 2 / 4;
    }
    .box:nth-child(2) {
      grid-area: 1 / 4 / 2 / 8;
    }
    .box:nth-child(3) {
      grid-area: 1 / 8 / 2 / 13;
    }
    .box:nth-child(4) {
      grid-area: 2 / 1 / 3 / 5;
    }
    .box:nth-child(5) {
      grid-area: 2 / 5 / 3 / 10;
    }
    .box:nth-child(6) {
      grid-area: 2 / 10 / 3 / 13;
    }
  }
  // 5 elements
  &.grid-5 {
    .box:nth-child(1) {
      grid-area: 1 / 1 / 3 / 4;
    }
    .box:nth-child(2) {
      grid-area: 1 / 4 / 2 / 10;
    }
    .box:nth-child(3) {
      grid-area: 1 / 10 / 2 / 13;
    }
    .box:nth-child(4) {
      grid-area: 2 / 4 / 3 / 7;
    }
    .box:nth-child(5) {
      grid-area: 2 / 7 / 3 / 13;
    }
  }
  // 4 elements
  &.grid-4 {
    .box:nth-child(1) {
      grid-area: 1 / 1 / 2 / 6;
    }
    .box:nth-child(2) {
      grid-area: 1 / 6 / 2 / 13;
    }
    .box:nth-child(3) {
      grid-area: 2 / 1 / 3 / 8;
    }
    .box:nth-child(4) {
      grid-area: 2 / 8 / 3 / 13;
    }
  }
  // 3 elements
  &.grid-3 {
    .box {
      min-height: 540px;
      height: 56vh;
    }
    .box:nth-child(1) {
      grid-area: 1 / 1 / 3 / 5;
    }
    .box:nth-child(2) {
      grid-area: 1 / 5 / 3 / 9;
    }
    .box:nth-child(3) {
      grid-area: 1 / 9 / 3 / 13;
    }
  }
  // 2 elements
  &.grid-2 {
    .box {
      min-height: 540px;
      height: 56vh;
    }
    .box:nth-child(1) {
      grid-area: 1 / 1 / 3 / 7;
    }
    .box:nth-child(2) {
      grid-area: 1 / 7 / 3 / 13;
    }
  }
  // 1 element
  &.grid-1 {
    .box {
      min-height: 540px;
      height: 56vh;
    }
    .box {
      grid-area: 1 / 1 / 3 / 13;
    }
  }
}

.container-main.flow-desk {
  width: calc(100% - 88px);
}

@media screen and (max-width: $small-desktop) {
  .configurator-boxes {
    display: flex;
    flex-wrap: wrap;
    .box {
      flex: 0 0 100%;
      margin: 0.5rem 0;
    }
  }
}

@media screen and (max-width: 600px) {
  .container-main .configurator-boxes .box {
    min-height: 250px !important;
    height: 40vh !important;
  }
}

.configurator-boxes {
  display: flex !important;
  height: 100%;
  min-height: 540px;
  overflow: auto;
  .box {
    display: flex !important;
    flex-basis: 100% !important;
    grid-area: unset !important;
    min-height: 540px !important;
  }
}
